import React, { useRef, useState } from 'react'
import axios from '../axios'
import { toast } from 'react-toastify';
import Loader from '../Components/Loader';



const ChangePassword = (props) => {
    const DivRef = useRef(null);
    const [loader, setLoader] = useState(false);

    const [OldPassword, setOldPassword] = useState("");
    const [OldPasswordError, setOldPasswordError] = useState("");
    const [Password, setPassword] = useState("");
    const [PasswordError, setPasswordError] = useState("");
    const [CPassword, setCPassword] = useState("");
    const [CPasswordError, setCPasswordError] = useState("");

    const HandleClose = () => {
        props.setChangePasswordFlag(false);
    }

    const ClosePopup = (event) => {
        if (DivRef.current && !DivRef.current.contains(event.target)) {
            HandleClose()
        }
    }

    const HandleSubmit = (e) => {
        e.preventDefault();
        setOldPasswordError("")
        setPasswordError("")
        setCPasswordError("")
        var flag = true;

        if (OldPassword === "") {
            setOldPasswordError("Please enter your old password.")
            flag = false;
        }
        if (Password === "") {
            setPasswordError("Please enter new password.")
            flag = false;
        }
        if (CPassword === "") {
            setCPasswordError("Please enter confirm password")
            flag = false;
        }

        if (Password !== CPassword) {
            setCPasswordError("Your confirm password do not match with password")
            flag = false;
        }

        if (flag) {
            setLoader(true)
            axios
                .post("change_password", {
                    "UserId": localStorage.getItem('Id'),
                    "OldPassword": OldPassword,
                    "NewPassword": Password
                })
                .then((response) => {
                    toast.success(response.data.status.message)
                    props.setChangePasswordFlag(false)
                    setLoader(false)
                })
                .catch((error) => {
                    setLoader(false)
                });
        }
    }


    return (
        <>
            {loader ?
                <Loader />
                : null}

            <div
                style={{
                    position: "absolute",
                    zIndex: 99999999,
                    height: "107vh",
                    width: "100%",
                    top: -60,
                    left: 0,
                    display: "flex",
                    background: 'rgba(0, 0, 0, 0.5)'
                }}
                onClick={(e) => {
                    ClosePopup(e)
                }}
            >
                <div
                    className="card"
                    style={{
                        width: "35%",
                        height: "fit-content",
                        margin: "auto",
                        textAlign: "center",
                        boxShadow: "0px 0px 5px 0px #5c5c5c",
                        maxHeight: '85vh'
                    }}
                    ref={DivRef}
                >
                    <div className="card-body horizontal-scroll mt-4">
                        <i className="bx bx-x" title="Close" style={{ position: 'absolute', right: '20px', top: '5px', cursor: 'pointer', fontSize: '23px', fontWeight: 'bold' }} onClick={(e) => {
                            HandleClose()
                        }}></i>

                        <div className="row  ">
                            <div className="col-md-12 mt-1">
                                <div className='row'>
                                    <div className='col-md-12' >
                                        <h4 style={{ display: "flex", alignContent: "center", justifyContent: "center" }}><i className="bx bx-key mx-2" type="solid" style={{ fontSize: "29px" }} ></i>Change Password</h4>
                                    </div>
                                </div>
                            </div>

                            <form onSubmit={HandleSubmit}>
                                <div className="card-body p-md-5 ">
                                    <div className="my-2" >
                                        <label className="m-1 form-label" style={{
                                            textAlign: "center", display: "flex"
                                        }}>Old Password</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="old_password"
                                            autoComplete='off'
                                            onChange={(e) =>
                                                setOldPassword(e.target.value)
                                            }
                                            value={OldPassword}
                                        />
                                        <p className="text-danger mt-1" style={{ display: "flex" }}>{OldPasswordError}</p>
                                    </div>
                                    <div className="my-2" >
                                        <label className="m-1 form-label" style={{
                                            textAlign: "center", display: "flex"
                                        }}> Password</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="password"
                                            autoComplete='off'
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                            value={Password}
                                        />
                                        <p className="text-danger mt-1" style={{ display: "flex" }}>{PasswordError}</p>
                                    </div>
                                    <div className="my-2" >
                                        <label className="m-1 form-label" style={{
                                            textAlign: "center", display: "flex"
                                        }}>Confirm Password</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="oconfirm_password"
                                            autoComplete='off'
                                            onChange={(e) =>
                                                setCPassword(e.target.value)
                                            }
                                            value={CPassword}
                                        />
                                        <p className="text-danger mt-1" style={{ display: "flex" }}>{CPasswordError}</p>
                                    </div>
                                    <div className="d-grid gap-2">
                                        <button type="submit" className="btn btn-primary btn-md">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}

export default ChangePassword