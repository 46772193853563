import React, { useEffect, useRef, useState } from 'react'
import { TICKET_STATUS_OPEN, TICKET_STATUS_POWER_CONFIRMATION, TICKET_STATUS_INCIDENT_DISPATCHED, TICKET_STATUS_DEFERRED, SECOND_TO_DHMS } from '../../Custom'

const BsPage1 = (props) => {
    const [height, setHeight] = useState(0)
    const Ref = useRef(null)

    useEffect(() => {
        setHeight(Ref.current.clientHeight)
    },[Ref])

    return (
        <>
            <div className="row">
                <div className="col-sm-6" >
                    <div
                        className="row dashboard-client-card justify-content: center"
                        onClick={() => {

                        }}
                        style={
                            { border: "1px solid gray" }

                        }
                        ref={Ref}
                    >
                        <div className="col-sm-12 mb-2 text-center">
                            <h4 className="mb-2"> <b>Open Tickets</b></h4>
                        </div>

                        <div className="col-sm-12 text-center p-3 mb-4 total-title" >
                            <h5 className='p-0 m-0 mb-2'><b>Total Numbers of Tickets</b></h5>
                            <h5 className="p-0 m-0"><strong>{props.AnalyticalData.TotalOpenTicketCounts ? props.AnalyticalData.TotalOpenTicketCounts : "0"}</strong></h5>
                        </div>


                        <div className="row custom-ttr-font">
                            <div className="col-sm-4 text-center mb-3">
                                <h6 className="mb-3">Ticket status</h6>
                                <div className='p-2 custom-status-col'>
                                    <div className='text-center my-3 ' onClick={() => {
                                        if (props.AnalyticalData.OpenTicketCounts) {
                                            props.HandleDetailsPopUp(TICKET_STATUS_OPEN)
                                        }
                                    }}>
                                        <p className='m-0 cursor-pointer'>Open</p>
                                        <p className='m-0 cursor-pointer'><strong>{props.AnalyticalData.OpenTicketCounts ? props.AnalyticalData.OpenTicketCounts : "0"}</strong></p>
                                    </div>

                                    <div className='text-center my-3 ' onClick={() => {
                                        if (props.AnalyticalData.PowerConfirmationTicketCounts) {
                                            props.HandleDetailsPopUp(TICKET_STATUS_POWER_CONFIRMATION)
                                        }
                                    }}>
                                        <p className='m-0 cursor-pointer'>Power Confirmation</p>
                                        <p className='m-0 cursor-pointer'><strong>{props.AnalyticalData.PowerConfirmationTicketCounts ? props.AnalyticalData.PowerConfirmationTicketCounts : "0"}</strong></p>
                                    </div>

                                    <div className='text-center my-3' onClick={() => {
                                        if (props.AnalyticalData.IncidentDispatchedTicketCounts) {
                                            props.HandleDetailsPopUp(TICKET_STATUS_INCIDENT_DISPATCHED)
                                        }
                                    }}>
                                        <p className='m-0 cursor-pointer'>Incident Dispatched</p>
                                        <p className='m-0 cursor-pointer'><strong>{props.AnalyticalData.IncidentDispatchedTicketCounts ? props.AnalyticalData.IncidentDispatchedTicketCounts : "0"}</strong></p>
                                    </div>

                                    <div className='text-center my-3' onClick={() => {
                                        if (props.AnalyticalData.DeferredTicketCounts) {
                                            props.HandleDetailsPopUp(TICKET_STATUS_DEFERRED)
                                        }
                                    }}>
                                        <p className='m-0 cursor-pointer'>Deferred</p>
                                        <p className='m-0 cursor-pointer'><strong>{props.AnalyticalData.DeferredTicketCounts ? props.AnalyticalData.DeferredTicketCounts : "0"}</strong></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-4 text-center mb-3">
                                <h6 className="mb-3">Ageing (SLA)</h6>
                                <div className='p-2 custom-status-col'>
                                    <div className='text-center my-3'>
                                        <p className='m-0'>0 – 2 Days</p>
                                        <p className='m-0'><strong>
                                            {props.AnalyticalData.TwoDaysAgeingSlaCounts ? props.AnalyticalData.TwoDaysAgeingSlaCounts : "0"}
                                        </strong></p>
                                    </div>

                                    <div className='text-center my-3'>
                                        <p className='m-0'>{" >2 – 5 Days"} </p>
                                        <p className='m-0'><strong>
                                            {props.AnalyticalData.TwoToFiveDaysAgeingSlaCounts ? props.AnalyticalData.TwoToFiveDaysAgeingSlaCounts : "0"}
                                        </strong></p>
                                    </div>

                                    <div className='text-center my-3'>
                                        <p className='m-0'>{">5 Days"}</p>
                                        <p className='m-0'><strong>
                                            {props.AnalyticalData.GreaterFiveAgeingSlaCounts ? props.AnalyticalData.GreaterFiveAgeingSlaCounts : "0"}
                                        </strong></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4 text-center mb-3">
                                <h6 className="mb-3">Ageing (Actual)</h6>
                                <div className='p-2 custom-status-col'>
                                    <div className='text-center my-3'>
                                        <p className='m-0'>0 – 2 Days</p>
                                        <p className='m-0'><strong>
                                            {props.AnalyticalData.TwoDaysAgeingActualCounts ? props.AnalyticalData.TwoDaysAgeingActualCounts : "0"}
                                        </strong></p>
                                    </div>

                                    <div className='text-center my-3'>
                                        <p className='m-0'>{" >2 – 5 Days"} </p>
                                        <p className='m-0'><strong>
                                            {props.AnalyticalData.TwoToFiveDaysAgeingActualCounts ? props.AnalyticalData.TwoToFiveDaysAgeingActualCounts : "0"}
                                        </strong></p>
                                    </div>

                                    <div className='text-center my-3'>
                                        <p className='m-0'>{">5 Days"}</p>
                                        <p className='m-0'><strong>
                                            {props.AnalyticalData.GreaterFiveAgeingActualCounts ? props.AnalyticalData.GreaterFiveAgeingActualCounts : "0"}
                                        </strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6"  >
                    <div
                        className="row dashboard-client-card justify-content: center"
                        onClick={() => {

                        }}
                        style={
                            { border: "1px solid gray",height :height }

                        }
                    >
                        <div className="col-sm-12 mb-2 text-center">
                            <h4 className="mb-2"><b>Closed Ticket Performance</b></h4>
                        </div>

                        <div className="col-sm-12 text-center p-3 mb-4 total-title" >
                            <h5 className='p-0 m-0 mb-2'><b>Total Numbers of Tickets</b></h5>
                            <h5 className="p-0 m-0"><strong>
                                {props.AnalyticalData.TotalClosedTicketCounts ? props.AnalyticalData.TotalClosedTicketCounts : "0"}
                            </strong></h5>
                        </div>


                        <div className="row">
                            <div className="col-sm-12 text-center mb-3">
                                <div className="row custom-ttr-font">

                                    <div className="col-sm-4 d-flex align-items-center">
                                        <h6 className="m-0">MTTD (Average)</h6>
                                    </div>

                                    <div className="col-sm-8">
                                        <div className='p-2 custom-status-col'>
                                            <div className='text-center my-3'>
                                                <p className='m-0'><strong>
                                                    {props.AnalyticalData.TotalTtdDiff ? SECOND_TO_DHMS(props.AnalyticalData.TotalTtdDiff) : "0"}
                                                </strong></p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 text-center mb-3">
                                <div className="row">

                                    <div className="col-sm-4 d-flex align-items-center">
                                        <h6 className="m-0">MTTR (Average)</h6>
                                    </div>

                                    <div className="col-sm-8">
                                        <div className='p-2 custom-status-col'>
                                            <div className='text-center my-3'>
                                                <p className='m-0'><strong>
                                                    {props.AnalyticalData.TotalTtrDiff ? SECOND_TO_DHMS(props.AnalyticalData.TotalTtrDiff) : "0"}
                                                </strong></p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 mb-3 custom-tt-note">
                                <p>MTTD – Meantime to Dispatch</p>
                                <p>MTTR – Meantime to Resolve</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>




        </>
    )
}

export default BsPage1