import React, { useEffect, useState, useRef } from 'react'
import axios from "../../axios";
import Loader from "../../Components/Loader";
import { CUSTOM_CLIENT_SELECT } from '../../Custom';
import ReactCountryFlag from "react-country-flag"
import { toast } from 'react-toastify';
import DefaultLogo from "../../Images/foxilogic-disable.png"

const BulkUploadPopUp = (props) => {
    const [loader, setLoader] = useState(false);
    const DivRef = useRef(null);

    const [UsergroupAll, setUsergroupAll] = useState([]);
    const [Usergroup, setUsergroup] = useState("");
    const [UsergroupError, setUsergroupError] = useState("");

    const [Roles, setRoles] = useState([]);
    const [Role, setRole] = useState("");
    const [RoleError, setRoleError] = useState("");

    const [ClientList, setClientList] = useState([]);
    const [Client, setClient] = useState("");
    const [ClientError, setClientError] = useState("");

    const [CountryAll, setCountryAll] = useState([]);
    const [Country, setCountry] = useState("");
    const [CountryError, setCountryError] = useState("");

    const [ChannelPartnerAll, setChannelPartnerAll] = useState([]);
    const [ChannelPartner, setChannelPartner] = useState("");
    const [ChannelPartnerError, setChannelPartnerError] = useState("");

    const HandleClose = () => {
        props.setBulkUploadFlag(false)
    }

    const ClosePopup = (event) => {
        if (DivRef.current && !DivRef.current.contains(event.target)) {
            HandleClose()
        }
    }

    useEffect(() => {
        setLoader(true);
        axios
            .get("masters")
            .then((response) => {
                setUsergroupAll(response.data.data.UserGroups);
                setRoles(response.data.data.UserGroups[0].roles);
                setChannelPartnerAll(response.data.data.ChannelPartners);
                setRoles(response.data.data.Roles)
                setLoader(false);
            })
            .catch(() => {
                setLoader(false);
            });
    }, []);

    useEffect(() => {
        setLoader(true);
        axios
            .get("clients")
            .then((response) => {

                var TempData = response.data.data;
                var arr = [];
                for (var i = 0; i < TempData.length; i++) {
                    var obj = TempData[i]
                    obj["label"] = TempData[i].Name;
                    obj["value"] = TempData[i].Id;
                    obj["image"] = TempData[i].ClientLogo ? TempData[i].ClientLogo : DefaultLogo;
                    arr.push(obj)
                }
                setClientList(arr)

                setLoader(false);
            })
            .catch(() => {
                setLoader(false);
            });
    }, []);

    const UsergroupHandler = (e) => {
        setClient("")
        setCountry("")
        setCountry("")
        setChannelPartner("")
        const value = e.target.value;

        if (parseInt(value) === 3 || parseInt(value) === 2) {
            setCountry('')
        }

        if (parseInt(value) === 2) {
            setClient('')
        }
        setUsergroup(value);
    };

    const getClientValue = () => {
        if (Client) {
            for (var i = 0; i < ClientList.length; i++) {
                if (ClientList[i].Id === parseInt(Client)) {
                    return [{ value: ClientList[i].Id, label: ClientList[i].Name, image: ClientList[i].image }];
                }
            }
        }
    }

    const OnchangeClient = (selected) => {
        var value = selected.Id;

        for (var i = 0; i < ClientList.length; i++) {
            if (ClientList[i].Id === parseInt(value)) {
                setCountryAll(ClientList[i].countries);
                break;
            }
        }
        setClient(value);
        setCountry("");
    };

    const handleValidation = () => {
        setUsergroupError("");
        setRoleError("");
        setClientError("");
        setCountryError("");
        setChannelPartnerError("");

        var flag = true;
        if (Usergroup === "") {
            setUsergroupError("Usergroup is required");
            flag = false;
        }

        if (Role === "") {
            setRoleError("Role is required");
            flag = false;
        }

        if (Usergroup === "3" || Usergroup === "4" || Usergroup === "5" || Usergroup === "6" || Usergroup === "7") {
            if (Client === "") {
                setClientError("Client is required");
                flag = false;
            }
        }

        if (Usergroup === "7") {
            if (ChannelPartner === "") {
                setChannelPartnerError("Channel Partner is required")
                flag = false;
            }
        }

        if (Usergroup === "4" || Usergroup === "5" || Usergroup === "6" || Usergroup === "7") {
            if (Country === "") {
                setCountryError("Country is required");
                flag = false;
            }
        }
        return flag;
    };

    const formSubmit = (e) => {
        e.preventDefault();
        const flag = handleValidation();
        if (flag) {
            const Payload = {
                "UserGroupId": Usergroup,
                "RoleId": Role,
                "ClientId": Client,
                'ClientCountryId': Country,
                "ChannelPartner": ChannelPartner
            };
            console.log(Payload, "payload")
            // setLoader(true);
            // axios
            //     .post("user", payload)
            //     .then((response) => {
            //         toast.success(response.data.status.message);
            //         setLoader(false);
            //     })
            //     .catch((response) => {
            //         setLoader(false);
            //     });
        }
    };

    return (
        <>
            {loader ? <Loader /> : null}

            <div
                style={{
                    position: "absolute",
                    zIndex: 999999,
                    height: "100vh",
                    width: "100%",
                    top: -60,
                    left: 0,
                    display: "flex",
                    background: 'rgba(0, 0, 0, 0.5)'
                }}
                onClick={(e) => {
                    ClosePopup(e)
                }}
            >
                <div
                    className="card"
                    style={{
                        width: "32%",
                        height: "fit-content",
                        margin: "auto",
                        boxShadow: "0px 0px 5px 0px #5c5c5c",
                        maxHeight: '85vh'
                    }}
                    ref={DivRef}
                >
                    <div className="card-body mt-1">
                        <i className="bx bx-x" title="Close" style={{ position: 'absolute', right: '20px', top: '5px', cursor: 'pointer', fontSize: '23px', fontWeight: 'bold' }} onClick={(e) => {
                            HandleClose
                                ()
                        }}></i>

                        <div className="row m-5">
                            <div className="col-md-12 text-center row">
                                <h4>Bulk Upload Users</h4>
                            </div>

                            <div className="col-md-12 mt-5" style={{ overflow: "auto" }}>
                                <form className="row g-3" onSubmit={formSubmit}>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-12 mt-3">
                                                <label htmlFor="inputState" className="form-label">
                                                    User group
                                                </label>
                                                <select
                                                    className="form-select"
                                                    onChange={UsergroupHandler}
                                                    value={Usergroup}
                                                >
                                                    <option value="">Select user group</option>
                                                    {UsergroupAll.map((item) => {
                                                        return (
                                                            <option key={item.Id} value={item.Id}>
                                                                {item.Name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                                <span className="text-danger">{UsergroupError}</span>
                                            </div>
                                            <div className="col-md-12 mt-3">
                                                <label htmlFor="inputState" className="form-label">
                                                    Role
                                                </label>
                                                <select
                                                    className="form-select"
                                                    onChange={(e) => setRole(e.target.value)}
                                                    value={Role}
                                                >
                                                    <option value="">Select role</option>
                                                    {Roles.map((item) => {
                                                        return (
                                                            <option key={item.Id} value={item.Id}>
                                                                {item.RoleName}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                                <span className="text-danger">{RoleError}</span>
                                            </div>
                                            {parseInt(Usergroup) === 3 || parseInt(Usergroup) === 4 || parseInt(Usergroup) === 5 || parseInt(Usergroup) === 6 || parseInt(Usergroup) === 7 ? (
                                                <div className="col-md-12 mt-3">
                                                    <label htmlFor="inputState" className="form-label">
                                                        Clients
                                                    </label>
                                                    {CUSTOM_CLIENT_SELECT({ ClientListOptions: ClientList, Value: getClientValue(), OnchangeClient: OnchangeClient })}
                                                    <span className="text-danger">{ClientError}</span>
                                                </div>
                                            ) : null}
                                            {parseInt(Usergroup) === 4 || parseInt(Usergroup) === 5 || parseInt(Usergroup) === 6 || parseInt(Usergroup) === 7 ? (
                                                <div className="col-md-12 mt-3">
                                                    <label htmlFor="inputState" className="form-label">
                                                        Country
                                                    </label>
                                                    <select
                                                        className="form-select"
                                                        onChange={(e) => setCountry(e.target.value)}
                                                        value={Country}
                                                    >
                                                        <option value="">Select Country</option>
                                                        {CountryAll.map((item) => {
                                                            return (
                                                                <option key={item.Id} value={item.Id}>

                                                                    {item.CountryCode ?
                                                                        <ReactCountryFlag
                                                                            className="add_country_flag mx-5"
                                                                            countryCode={item.CountryCode}
                                                                            aria-label={item.CountryCode}
                                                                            style={{
                                                                            }}
                                                                        />
                                                                        : <i
                                                                            className="bx bx-flag"
                                                                            title="Manage country"
                                                                            style={{ fontSize: "20px", cursor: "pointer", marginRight: "10px" }}
                                                                        ></i>
                                                                    }

                                                                    &nbsp;&nbsp;&nbsp;{item.CountryName}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <span className="text-danger">{CountryError}</span>
                                                </div>
                                            ) : null}

                                            {parseInt(Usergroup) === 7 ? (
                                                <div className="col-md-12 mt-3">
                                                    <label htmlFor="inputState" className="form-label">
                                                        Channel Partner
                                                    </label>
                                                    <select
                                                        className="form-select"
                                                        onChange={(e) => setChannelPartner(e.target.value)}
                                                        value={ChannelPartner}
                                                    >
                                                        <option value="">Select Channel Partner</option>
                                                        {ChannelPartnerAll.map((item) => {
                                                            return (
                                                                <option key={item.Id} value={item.Id}>
                                                                    {item.Name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <span className="text-danger">{ChannelPartnerError}</span>
                                                </div>
                                            ) : null}

                                            <div className="col-md-12 mt-3">
                                                <input type="file"
                                                    className="form-control"
                                                    name="users"></input>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 text-center">
                                        <button type="submit" className="btn btn-primary px-5">
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BulkUploadPopUp