import React, { useEffect, useState, useRef } from 'react'
import axios from "../../axios";
import Loader from "../../Components/Loader";
import ReactCountryFlag from 'react-country-flag';

const Callback = (props) => {
    const [ModuleData, setModuleData] = useState([])
    const DivRef = useRef(null);
    const [SelectedCallback, setSelectedCallback] = useState(0)
    const [loader, setLoader] = useState(false)

    const HandleClose = () => {
        props.setCountryCallback({});
    }

    useEffect(() => {
        if (props.CountryCallback.Id) {
            setLoader(true)
            axios.post('system_callback', {
                "CountryId": props.CountryCallback.Id
            }).then((response) => {
                setModuleData(response.data.data)
                setLoader(false)

            }).catch(() => {
                setLoader(false)
            })
        }

    }, [props.CountryCallback])

    const handleCloseClick = (event) => {
        if (DivRef.current && !DivRef.current.contains(event.target)) {
            HandleClose()
        }
    }

    return (
        <>
            {loader ? <Loader /> : null}
            <div
                style={{
                    position: "absolute",
                    zIndex: 999999,
                    height: "100vh",
                    width: "100%",
                    top: -60,
                    left: 0,
                    display: "flex",
                    background: 'rgba(0, 0, 0, 0.5)'
                }}
                onClick={(e) => {
                    handleCloseClick(e)
                }}
            >
                <div
                    className="card"
                    style={{
                        width: "40%",
                        height: "fit-content",
                        margin: "auto",
                        textAlign: "center",
                        boxShadow: "0px 0px 5px 0px #5c5c5c",
                        maxHeight: '85vh'
                    }}
                    ref={DivRef}
                >
                    <div className="card-body mt-4">
                        <i className="bx bx-x"
                            title="Close"
                            style={{
                                position: 'absolute',
                                right: '20px',
                                top: '5px',
                                cursor: 'pointer',
                                fontSize: '23px',
                                fontWeight: 'bold'
                            }}
                            onClick={HandleClose}
                        ></i>

                        <div className='row'>
                            <h5>
                                {props.CountryCallback.CountryCode ? <ReactCountryFlag
                                    style={{ marginRight: "10px !important" }}

                                    className="mx-2 country_flag_in_listing"
                                    countryCode={props.CountryCallback.CountryCode}
                                />
                                    :
                                    <i
                                        className="bx bx-flag mb-4"
                                        title="Manage country"
                                        style={{ fontSize: "32px", cursor: "pointer", marginRight: "10px" }}
                                    ></i>
                                }
                                {props.CountryCallback.CountryName}</h5>
                        </div>

                        <div className="p-2 gap-3 mb-3" style={{
                            padding: '0px 10px', display: "flex",
                            justifyContent: "center"
                        }}>
                            {
                                ModuleData.length > 0 ?
                                    ModuleData.map((item, i) => {
                                        return (
                                            <div
                                                className=" gap-4 px-2 py-1"
                                                style={{
                                                    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 8px 0px',
                                                    borderRadius: '6px',
                                                    border: SelectedCallback === i ? '1px solid #243c29' : "",
                                                    fontSize: "17px",
                                                    backgroundColor: "#fbe5ce"
                                                }}
                                                onClick={() => {
                                                    setSelectedCallback(i);
                                                }}
                                            >
                                                <div className="d-flex align-items-center gap-1">
                                                    <p className='m-0'><b>{item.CallbackName}</b></p>
                                                </div>
                                            </div>
                                        )
                                    }) : null
                            }
                        </div>
                        <hr />

                        <div className="row  justify-content-center my-5">

                            {
                                ModuleData[SelectedCallback] ?
                                    <div className="col-sm-10">
                                        <div className="card mb-0">
                                            <div className="card-header py-2 gap-2 cron-status-card-header" style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                textAlign: "left",
                                                fontWeight: "bold"
                                            }} >
                                                {ModuleData[SelectedCallback].Description}
                                            </div>
                                            <div className="row justify-content-left p-3">
                                                <div className="col-sm-11">
                                                    {/* <div className="card p-3 my-1 custom-indicator-card gap-2 indicator-green-bg">
                                                        <p className="m-0"></p>
                                                    </div> */}

                                                </div>
                                                <div className='col-md-12 my-3 mx-3' style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    textAlign: "left"
                                                }}>
                                                    <p className="m-1"><b>Endpoint :</b> {ModuleData[SelectedCallback].EndPoint}</p>
                                                    <p className="m-1"><b>{"Method"}   &nbsp;&nbsp;:</b> {ModuleData[SelectedCallback].Method}</p>
                                                    <p className="m-1"><b>Header's :</b></p>
                                                    <div style={{ marginLeft: "80px" }}>
                                                        {
                                                            ModuleData[SelectedCallback].Headers.length > 0 ?
                                                                ModuleData[SelectedCallback].Headers.map((val, index) => {
                                                                    return (
                                                                        <p className='mb-0'>
                                                                            {index + 1 + ".  " + val}
                                                                        </p>
                                                                    )
                                                                })
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div >
        </>

    )
}

export default Callback