import axios from "../../axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../Components/Loader";
import { ToastContainer } from "react-toastify";
import { AlPHA_NUMERIC_KEY } from "../../Custom";

const AddClient = () => {
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [Name, setName] = useState("");
  const [NameError, setNameError] = useState("");
  const [LogoError, setLogoError] = useState("");
  const [Logo, setLogo] = useState("")
  const [LogoImage, setLogoImage] = useState("")
  const [ClientCode, setClientCode] = useState("");
  const [ClientCodeError, setClientCodeError] = useState("");

  const handleValidation = () => {
    setNameError("");
    setClientCodeError("")
    var flag = true;
    if (Name === "") {
      setNameError("Name is required");
      flag = false;
    }
    // if (Logo === "") {
    //   setLogoError("Client Logo is required");
    //   flag = false;
    // }

    const alphanumericRegex = /^[a-zA-Z0-9]{8}$/;
    if (ClientCode === "") {
      setClientCodeError("Client Code is required");
      flag = false;
    } else if (!alphanumericRegex.test(ClientCode)) {
      setClientCodeError(" Client code must be 8 digit alpha numeric");
      flag = false;
    }
    return flag;
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setNameError("")
    setLogoError("")
    const flag = handleValidation();
    if (flag) {
      if (id) {
        const payload = new FormData();
        payload.append("Name", Name);
        payload.append("ClientCode", ClientCode);
        console.log(Logo, "Logo")
        if (typeof Logo !== "string") {
          payload.append("ClientLogo", Logo !== "" && typeof Logo !== 'undefined' ? Logo : "");
        }

        setLoader(true);
        axios
          .post("client/" + id + "?_method=PUT", payload)
          .then((response) => {
            navigate("/clients", {
              state: response.data.status.message,
            });
            setLoader(false);
          })
          .catch(() => {
            setLoader(false);
          });
      } else {

        const payload = new FormData();
        payload.append("Name", Name);
        payload.append("ClientCode", ClientCode);
        payload.append("ClientLogo", Logo !== "" && typeof Logo !== 'undefined' ? Logo : "");
        payload.append("ClientApiKey", AlPHA_NUMERIC_KEY());
        setLoader(true);
        axios
          .post("client", payload)
          .then((response) => {
            navigate("/clients", {
              state: response.data.status.message,
            });
            setLoader(false);
          })
          .catch(() => {
            setLoader(false);
          });
      }
    }
  };

  useEffect(() => {
    if (id) {
      setLoader(true);
      axios
        .get("client/" + id)
        .then((response) => {
          setLogo(response.data.data.ClientLogo)
          setLogoImage(response.data.data.ClientLogo)
          setName(response.data.data.Name);
          setClientCode(response.data.data.ClientCode);
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [id]);

  const onchangeLogo = (event) => {
    setLogo("")
    setLogoError("")
    setLogoImage("")
    var file_name_arr = event.target.files[0].name.split(".");
    var img_height;
    var img_width;

    var _URL = window.URL || window.webkitURL;

    var file, img;
    file = event.target.files[0]
    img = new Image();
    img.onload = function () {
      img_width = this.width;
      img_height = this.height;

      if (img_width !== img_height) {
        setLogoError("Please select square image.")
        return false;
      } else {
        if (event.target.files[0].size / 1024 > 500) {
          setLogoError("File size must not be greater than to 500Kb.")
          return
        } else if (!(file_name_arr[file_name_arr.length - 1] === "png" || file_name_arr[file_name_arr.length - 1] === "jpg" || file_name_arr[file_name_arr.length - 1] === "jpeg")) {
          setLogoError("Please select png or jpg image.")
        } else {
          setLogo(event.target.files[0]);
          setLogoImage(URL.createObjectURL(event.target.files[0]))
        }
      }
    };

    img.src = _URL.createObjectURL(file);
  }

  return (
    <>
      <ToastContainer theme="colored" />
      {loader ? <Loader /> : null}
      <div className="row">
        <div className="col-xl-10 mx-auto">
          <div className="card">
            <div className="card-body p-5">
              <div className="card-title">
                <div className="row">
                  <div className="col-md-8">
                    <h5 className="mb-0 text-primary">
                      <i className="bx bx-user-circle me-1 font-22"></i>
                      {id ? "Edit Client" : "Add Client"}
                    </h5>
                  </div>
                  <div className="col-md-4 text-right">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <hr />
              <form className="row g-3" onSubmit={formSubmit}>
                <div className="col-md-6">
                  <label htmlFor="inputFirstName" className="form-label">
                    Client name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setName(e.target.value)}
                    value={Name}
                  />
                  <span className="text-danger">{NameError}</span>
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputceintcode" className="form-label">
                    Client code
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setClientCode(e.target.value)}
                    value={ClientCode}
                  />
                  <span className="text-danger">{ClientCodeError}</span>
                </div>

                <div className="col-md-6">
                  <input
                    type="file"
                    className="form-control"
                    name="client_logo"
                    accept=
                    "image/jpeg, image/png"
                    onChange={(event) => {
                      if (event.target.files && event.target.files[0]) {
                        onchangeLogo(event);
                      }
                    }}
                  />

                  {
                    LogoImage !== "" && typeof LogoImage !== 'undefined' ? <img
                      src={LogoImage}
                      className="mt-3 user-img"
                      alt="Client Logo"
                      style={{
                        border: "1px solid white",
                        marginLeft: "118px",
                        width: "90px",
                        height: "90px",
                      }}
                    /> : ""
                  }
                  <div className="text-danger mt-1">{LogoError}</div>
                </div>
                <div className="row mt-3">
                  <div className="col-11">
                    <button type="submit" className="btn btn-primary px-5">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddClient;
